import * as React from 'react';

export const MailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 36">
      <path d="M3.902.246c-.767 0-1.471.237-2.073.63l21.968 20.81c.963.913 2.21.913 3.17 0L48.897.877a3.762 3.762 0 0 0-2.073-.63H3.902zM.244 2.93A4.12 4.12 0 0 0 0 4.29v27.028c0 .822.229 1.578.63 2.215L16.156 18.03.244 2.929zm50.237 0l-15.872 15.08 15.486 15.525c.4-.637.63-1.393.63-2.215V4.291a4.12 4.12 0 0 0-.244-1.362zM32.719 19.817l-3.963 3.76a4.909 4.909 0 0 1-6.747 0l-3.963-3.74L2.683 35.16c.384.13.792.203 1.219.203h42.92a3.77 3.77 0 0 0 1.22-.203L32.72 19.817z" />
    </svg>
  );
};

MailIcon.displayName = 'MailIcon';

export default MailIcon;
