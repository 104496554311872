import * as React from 'react';

export const LogoSpotify: React.SFC<{}> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49 49"
      aria-label="Logo do Spotify"
      fill="#24CF5F"
    >
      <path d="M24.5 0C11.025 0 0 11.025 0 24.5S11.025 49 24.5 49 49 37.975 49 24.5 38.097 0 24.5 0zm11.272 35.403c-.49.733-1.347.98-2.084.49-5.758-3.553-12.986-4.29-21.563-2.33-.853.249-1.59-.366-1.835-1.1-.245-.86.368-1.593 1.103-1.838 9.31-2.084 17.395-1.225 23.764 2.695.858.367.979 1.346.615 2.083zm2.94-6.738c-.615.858-1.717 1.225-2.577.613-6.612-4.043-16.657-5.268-24.375-2.818-.978.245-2.082-.245-2.328-1.225-.245-.98.245-2.084 1.226-2.33 8.942-2.693 19.967-1.343 27.562 3.31.737.37 1.102 1.593.492 2.45zm.245-6.86c-7.842-4.655-20.95-5.145-28.422-2.816-1.225.366-2.45-.369-2.817-1.472-.368-1.226.367-2.45 1.47-2.819 8.697-2.572 23.03-2.082 32.096 3.31 1.101.612 1.468 2.082.856 3.184-.61.86-2.083 1.224-3.183.613z" />
    </svg>
  );
};

LogoSpotify.displayName = 'LogoSpotify';

export default LogoSpotify;
