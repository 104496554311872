import * as React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import LogoMedium from './icons/LogoMedium';
import LogoSlack from './icons/LogoSlack';
import LogoSpotify from './icons/LogoSpotify';
import theme from '../styles/theme';
import MailIcon from './icons/MailIcon';
import LogoFacebook from './icons/LogoFacebook';
import LogoTwitter from './icons/LogoTwitter';
import LogoLinkedin from './icons/LogoLinkedin';

interface ISocialLinksProps {
  config: {
    social: {
      facebook: string;
      linkedin: string;
      twitter: string;
    };
    podcast: {
      spotify: string;
    };
  };
  blogPage: {
    cta: {
      email: string;
    };
  };
}

const Wrapper = styled.aside`
  margin: 4rem auto 3.25rem;
`;

const circleStyles = `
  font-size: 3.75rem;
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow:  ${theme.boxShadow.two};
  border-radius: 50%;
  margin: 0 .3em;
  transition: ${theme.transitions.default};
  :hover, :focus {
    outline: none;
    box-shadow: ${theme.boxShadow.three};
    transform: scale(1.05);
  }
  svg {
    max-width: 100%;
    width: 50%;
  }
`;

const CircleAnchor = styled.a`
  ${circleStyles};
`;

const CircleLink = styled(Link)`
  ${circleStyles};
`;

const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  :last-of-type {
    margin-top: 1rem;
    a {
      font-size: 3rem;
    }
  }
`;

export const SocialLinksTemplate: React.SFC<ISocialLinksProps> = props => {
  const { config, blogPage } = props;
  return (
    <Wrapper>
      <CircleWrapper>
        <CircleAnchor
          href={config.podcast.spotify}
          target="_blank"
          rel="noopener"
        >
          <LogoSpotify />
        </CircleAnchor>
        <CircleAnchor
          href={`mailto:${blogPage.cta.email}`}
          target="_blank"
          rel="noopener"
        >
          <MailIcon />
        </CircleAnchor>
        <CircleLink to="/slack">
          <LogoSlack />
        </CircleLink>
      </CircleWrapper>
      {/* Círculos menores para os links menos importantes */}
      <CircleWrapper>
        <CircleAnchor
          href="https://medium.com/data-hackers"
          target="_blank"
          rel="noopener"
        >
          <LogoMedium />
        </CircleAnchor>
        <CircleAnchor
          href={config.social.facebook}
          target="_blank"
          rel="noopener"
        >
          <LogoFacebook />
        </CircleAnchor>
        <CircleAnchor
          href={config.social.twitter}
          target="_blank"
          rel="noopener"
        >
          <LogoTwitter />
        </CircleAnchor>
        <CircleAnchor
          href={config.social.linkedin}
          target="_blank"
          rel="noopener"
        >
          <LogoLinkedin />
        </CircleAnchor>
      </CircleWrapper>
    </Wrapper>
  );
};

export const SocialLinks: React.SFC<{}> = () => (
  <StaticQuery
    query={graphql`
      query socialLinksQuery {
        config: sanityConfig {
          social {
            facebook
            linkedin
            twitter
          }
          podcast {
            spotify
          }
        }
        blogPage: sanityBlog(_id: { eq: "blogId" }) {
          cta {
            email
          }
        }
      }
    `}
    render={SocialLinksTemplate}
  />
);

SocialLinks.displayName = 'SocialLinks';

export default SocialLinks;
