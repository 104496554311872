import * as React from 'react';

export const LogoMedium: React.SFC<{}> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49 49"
      aria-label="Logo do Medium"
      fill="#000"
    >
      <path d="M0 0v49h49V0H0zm40.707 11.609l-2.628 2.52a.768.768 0 0 0-.292.738V33.38a.768.768 0 0 0 .292.737l2.566 2.52v.553H27.738v-.551l2.658-2.583c.262-.261.262-.337.262-.735V18.355l-7.391 18.773h-1l-8.608-18.773v12.582a1.735 1.735 0 0 0 .475 1.444l3.459 4.193v.554H7.789v-.551l3.46-4.196a1.674 1.674 0 0 0 .446-1.444V16.388a1.276 1.276 0 0 0-.415-1.076L8.205 11.61v-.551h9.543l7.377 16.176 6.484-16.178h9.098v.553z" />
    </svg>
  );
};

LogoMedium.displayName = 'LogoMedium';

export default LogoMedium;
