import * as React from 'react';

export const LogoSlack: React.SFC<{}> = () => {
  const clipId = `logo-slack__clip-${Math.random() * Math.random()}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49 49"
      aria-label="Logo do Spotify"
      fill="none"
    >
      <defs>
        <clipPath id={clipId}>
          <path fill="#fff" d="M0 0h49v49H0z" />
        </clipPath>
      </defs>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M31.809 2.97C31.069.743 28.665-.557 26.262.186c-2.22.742-3.514 3.155-2.774 5.568l11.28 34.894c.739 2.227 3.143 3.34 5.361 2.784 2.404-.742 3.699-3.155 2.959-5.382-.185-.372-11.28-35.08-11.28-35.08z"
          fill="#ECB32D"
        />
        <path
          d="M14.242 8.728C13.502 6.5 11.098 5.2 8.695 5.944c-2.22.742-3.513 3.155-2.774 5.568l11.28 34.708c.74 2.227 3.143 3.341 5.362 2.784 2.404-.742 3.698-3.155 2.958-5.382 0-.186-11.28-34.894-11.28-34.894z"
          fill="#63C1A0"
        />
        <path
          d="M45.86 31.738c2.218-.742 3.512-3.155 2.773-5.568-.74-2.227-3.144-3.526-5.547-2.784L8.508 34.894c-2.219.742-3.328 3.155-2.773 5.382.74 2.413 3.143 3.712 5.362 2.97.185-.186 34.762-11.508 34.762-11.508z"
          fill="#E01A59"
        />
        <path
          d="M15.714 41.761c2.22-.742 5.178-1.67 8.321-2.784-.74-2.227-1.664-5.197-2.774-8.352l-8.135 2.784 2.588 8.352z"
          fill="#331433"
        />
        <path
          d="M33.283 36.011c3.143-.928 6.102-2.042 8.32-2.784-.739-2.227-1.663-5.197-2.773-8.352l-8.32 2.784 2.773 8.352z"
          fill="#D62027"
        />
        <path
          d="M40.31 14.296c2.22-.742 3.514-3.155 2.774-5.568-.74-2.228-3.143-3.527-5.547-2.784L2.775 17.266C.74 18.008-.553 20.42.186 22.648c.74 2.413 3.144 3.712 5.363 2.97L40.31 14.296z"
          fill="#89D3DF"
        />
        <path
          d="M10.17 24.126c2.22-.742 5.178-1.67 8.321-2.784-1.11-2.97-2.034-5.94-2.773-8.167l-8.321 2.599 2.774 8.352z"
          fill="#258B74"
        />
        <path
          d="M27.554 18.378c3.144-.928 6.102-2.042 8.321-2.784-.924-3.156-2.034-6.125-2.773-8.353l-8.321 2.784 2.773 8.353z"
          fill="#819C3C"
        />
      </g>
    </svg>
  );
};

LogoSlack.displayName = 'LogoSlack';

export default LogoSlack;

export const AlternateSlackLogo = () => (
  <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.604 10.995l1.035 3.085 3.205-1.074-1.035-3.074-3.205 1.08v-.017z" />
    <path d="M19.549 14.055l-1.555.521.54 1.61a1.246 1.246 0 0 1-1.221 1.637 1.26 1.26 0 0 1-1.155-.849l-.54-1.607-3.21 1.073.539 1.608a1.249 1.249 0 0 1-1.23 1.64 1.266 1.266 0 0 1-1.155-.85l-.54-1.62-1.56.524c-.15.045-.286.061-.436.061a1.269 1.269 0 0 1-1.155-.855 1.235 1.235 0 0 1 .78-1.575l1.56-.525-.987-3.088-1.55.525a1.264 1.264 0 0 1-.429.064 1.247 1.247 0 0 1-1.14-.848A1.25 1.25 0 0 1 5.9 9.927l1.56-.52-.54-1.605a1.248 1.248 0 0 1 .796-1.575 1.239 1.239 0 0 1 1.574.783l.54 1.608 3.194-1.074-.54-1.605a1.256 1.256 0 0 1 .79-1.574 1.249 1.249 0 0 1 1.575.791l.54 1.621 1.555-.51a1.247 1.247 0 0 1 1.575.78 1.244 1.244 0 0 1-.784 1.574l-1.558.524 1.035 3.086 1.552-.516a1.248 1.248 0 0 1 1.575.795c.22.66-.136 1.365-.78 1.574l-.01-.029zm4.17-5.356C21.244.456 17.67-1.47 9.424 1.005 1.18 3.48-.747 7.051 1.73 15.301c2.475 8.245 6.046 10.17 14.297 7.694C24.27 20.52 26.196 16.95 23.72 8.7z" />
  </svg>
);
