import * as React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

import BasicLayout from '../../layouts/BasicLayout';
import { TitleWrapper } from '../../components/listPages/reusableComponents';
import PageMeta from '../../components/PageMeta';
import theme from '../../styles/theme';
import SocialLinks from '../../components/SocialLinks';
import { IPageMeta } from '../../components/PageMeta';

export interface IThankYouPageProps {
  pageContext: {
    body: string;
    meta: IPageMeta;
  };
}

const Container = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 3rem auto;
  border-radius: 0.5rem;
  background: white;
  color: ${theme.colors.darkPurple};
  padding: 1rem 3rem;
`;

export const GenericPage: React.SFC<IThankYouPageProps> = props => {
  const { body, meta } = props.pageContext;
  return (
    <BasicLayout>
      <PageMeta {...meta} />
      <TitleWrapper />
      <Container>
        <h1>{meta.title}</h1>
        <BlockContent blocks={body} />
      </Container>
      <SocialLinks />
    </BasicLayout>
  );
};

GenericPage.displayName = 'GenericPage';

export default GenericPage;
